import {
  listenForArticleChanged,
  listenForArticleQuantityChanges,
  listenForArticlesAdded,
  listenForBulkArticlesRemoved,
} from '../../lib/fragment-helper';
import { Article, SizingData } from '../../models/sizing-data';
import { CgGroupingLevel, SizingGroup } from '../../models/sizing-group';
import { actionMixin, makePropertiesObservable } from '../action-mixin';
import { CalculateArticleQuantitiesAction } from './calculate-article-quantities-action';
import { CalculateQuantitiesAction } from './calculate-quantities-action';
import { ChangeArticleQuantityAction } from './change-article-quantity-action';
import { ChangeArticleAction } from './change-article-action';
import { ChangeArticleSizingAction } from './change-article-sizing-action';
import { ChangeSizingAction } from './change-sizing-action';
import { GroupArticlesAction } from './group-articles-action';
import { LoadSizingAction } from './load-sizing-action';
import { ResetArticleQuantitiesAction } from './reset-article-quantities-action';
import { GetSizingDistributionAction } from './get-sizing-distribution-action';
import { GetBoxRestrictionsAction } from './get-box-restrictions-action';
import { SetGroupBoxRestrictionAction } from './set-group-box-restriction-action';
import { UpdateSizingDistributionAction } from './update-sizing-distribution-action';
import { ToggleGroupReviewAction } from './toggle-group-review-action';
import { ToggleEnableBoxAction } from './toggle-enable-box-action';
import { MarkAllGroupsReviewedAction } from './mark-all-groups-reviewed-action';
import { AddArticlesAction } from './add-articles-action';
import { RemoveArticlesAction } from './remove-articles-action';
import { ChangeGroupingLevelAction } from './change-grouping-level-action';
import { UpdateArticlesAction } from './update-articles-action';
import { Brand, ReferenceBrandsData } from '../../models/brand';
import { GetReferenceBrandsAction } from './get-reference-brands-action';
import { GetSilosAction } from './get-silos-action';
import { ApplyReferenceBrandAction } from './apply-reference-brand-action';
import { ApplySizingDecisionAction } from './apply-sizing-decision-action';
import { BoxRestriction } from '../../models/box-restriction';
import { OptimizeBoxQuantitiesAction } from './optimize-box-quantities-action';
import { UpdateBoxQuantitiesAction } from './update-box-quantities';
import { ResetBoxQuantitiesAction } from './reset-box-quantities';
import { AdditionalData } from '../../services/event-tracking';

export interface SizingStore
  extends GroupArticlesAction,
    LoadSizingAction,
    ChangeSizingAction,
    GetSizingDistributionAction,
    GetBoxRestrictionsAction,
    UpdateSizingDistributionAction,
    ChangeArticleQuantityAction,
    ChangeArticleAction,
    ChangeArticleSizingAction,
    CalculateQuantitiesAction,
    CalculateArticleQuantitiesAction,
    ResetArticleQuantitiesAction,
    ToggleGroupReviewAction,
    ToggleEnableBoxAction,
    MarkAllGroupsReviewedAction,
    ResetArticleQuantitiesAction,
    AddArticlesAction,
    RemoveArticlesAction,
    UpdateArticlesAction,
    ChangeGroupingLevelAction,
    GetReferenceBrandsAction,
    GetSilosAction,
    ApplyReferenceBrandAction,
    ApplySizingDecisionAction,
    SetGroupBoxRestrictionAction,
    UpdateBoxQuantitiesAction,
    ResetBoxQuantitiesAction,
    OptimizeBoxQuantitiesAction {}

export class SizingStore extends actionMixin(
  GroupArticlesAction,
  LoadSizingAction,
  ChangeSizingAction,
  GetSizingDistributionAction,
  GetBoxRestrictionsAction,
  UpdateSizingDistributionAction,
  ChangeArticleQuantityAction,
  ChangeArticleAction,
  ChangeArticleSizingAction,
  CalculateQuantitiesAction,
  CalculateArticleQuantitiesAction,
  ResetArticleQuantitiesAction,
  ToggleGroupReviewAction,
  ToggleEnableBoxAction,
  MarkAllGroupsReviewedAction,
  AddArticlesAction,
  RemoveArticlesAction,
  UpdateArticlesAction,
  ChangeGroupingLevelAction,
  GetReferenceBrandsAction,
  GetSilosAction,
  ApplyReferenceBrandAction,
  ApplySizingDecisionAction,
  SetGroupBoxRestrictionAction,
  UpdateBoxQuantitiesAction,
  ResetBoxQuantitiesAction,
  OptimizeBoxQuantitiesAction
) {
  public referenceId: string;
  public orderName: string;
  public brandCode: string;
  public brandName: string;
  public supplierCode: string;
  public subUnitCode: string;
  public seasonCode: string;
  public cgCode1: string;
  public cgCode2: string;
  public readOnly: boolean;
  public shared: boolean;
  public articles: Article[];
  public sizingGroups: SizingGroup[] = [];
  public noLinkedSizeCharts = false;
  public headCommodityGroups: { [code: string]: string } = {};
  public groupingCgLevel: CgGroupingLevel = CgGroupingLevel.CG5;
  public enableBox: boolean;
  public enableBoxAvailability: boolean;
  public boxRestrictions: BoxRestriction[];
  public referenceBrands: ReferenceBrandsData = {
    brandsMap: {},
    rawBrands: [],
    processedBrands: {
      usedBrands: [],
      similarBrands: [],
    },
  };
  public silos: string[] = [];
  public loadingSilos = false;
  public presizeLoaded: boolean;
  public mismatchedArticles: Article[] = [];
  public mismatchedGroups: Record<string, boolean> = {};
  public buyerEmail: string | undefined;
  public isNewOrder: boolean;
  public autoEnableBox: boolean;
  public groupByArticleRefId: Record<string, SizingGroup> = {};
  public groupById: Record<string, SizingGroup> = {};

  constructor(sizingData: SizingData, boxRestrictions: BoxRestriction[] = []) {
    super();
    this.referenceId = sizingData.referenceId;
    this.orderName = sizingData.orderName || '';
    this.brandCode = sizingData.brandCode;
    this.brandName = sizingData.brandName || sizingData.brandCode;
    this.supplierCode = sizingData.supplierCode;
    this.subUnitCode = sizingData.subunitCode;
    this.seasonCode = sizingData.seasonCode;
    this.articles = sizingData.articles;
    this.groupingCgLevel = sizingData.groupingCgLevel;
    this.enableBox = sizingData.enableBox;
    this.enableBoxAvailability = sizingData.enableBoxAvailability ?? false;
    this.boxRestrictions = boxRestrictions;
    this.readOnly = sizingData.readOnly;
    this.cgCode1 = this.articles[0].commodityGroup.codes[0];
    this.cgCode2 = this.articles[0].commodityGroup.codes[1];
    this.shared = !!sizingData.lastDownloaded;
    this.presizeLoaded = false;
    this.isNewOrder = false;
    this.autoEnableBox = false;

    // Will make all properties observable
    makePropertiesObservable(this);
    // Initial grouping
    this.groupArticles();
    this.pickHeadCommodityGroups();

    listenForArticleQuantityChanges(this);
    listenForArticleChanged(this);
    listenForArticlesAdded(this);
    listenForBulkArticlesRemoved(this);

    this.getReferenceBrandsWithUsage = this.getReferenceBrandsWithUsage.bind(this);
    this.getReferenceBrandByCode = this.getReferenceBrandByCode.bind(this);
    this.getSizingDistribution = this.getSizingDistribution.bind(this);
  }

  /**
   * This is not an action. It's a helper function to sort articles and used by actions
   * @param articles
   */
  sortArticlesByQuantity(articles: Article[]): Article[] {
    return articles.sort((a, b) => (a.quantity > b.quantity ? -1 : 1));
  }

  articlesByReferenceId(): Record<string, Article> {
    return this.articles.reduce(
      (articles: Record<string, Article>, article: Article) => ({
        ...articles,
        [article.referenceId]: article,
      }),
      {}
    );
  }

  sortGroupsByGroupNames(): SizingGroup[] {
    return this.sizingGroups.sort((groupA, groupB) => groupA.getGroupName().localeCompare(groupB.getGroupName()));
  }

  /**
   * Methods to get reference brand specific data
   * Not being used directly as observable because of challenge of use in each header
   */
  getReferenceBrandsWithUsage(): Record<string, Array<Brand>> {
    return this.referenceBrands.processedBrands;
  }
  getReferenceBrandByCode(code: string): Brand | undefined {
    return this.referenceBrands.brandsMap[code];
  }

  resetGroupByArticleRefId(): void {
    this.groupByArticleRefId = {};
    this.sizingGroups.forEach((group) => {
      group.articles.forEach((article) => {
        this.groupByArticleRefId[article.referenceId] = group;
      });
    });
  }

  resetGroupById(): void {
    this.groupById = {};
    this.sizingGroups.forEach((group) => (this.groupById[group.id] = group));
  }

  additionalData(): AdditionalData {
    return {
      supplierCode: this.supplier_code,
      subUnitCode: this.subUnitCode,
      buyerEmail: this.buyerEmail,
      brandCode: this.brandCode,
      brandName: this.brandName,
      orderReferenceId: this.referenceId,
      season: this.seasonCode,
      cgGroupingLevel: this.groupingCgLevel,
    };
  }

  /**
   * Pick head commodity groups of level 2
   * @private
   */
  private pickHeadCommodityGroups() {
    this.sizingGroups.forEach((group) => {
      this.headCommodityGroups[
        group.commodityGroup.codes[1]
      ] = `${group.commodityGroup.names[0]} / ${group.commodityGroup.names[1]}`;
    });
  }
}
