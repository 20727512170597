import { sendEventToProxyTracking } from '../lib/api';
import { Article } from '../models/sizing-data';
import { SizingGroup } from '../models/sizing-group';

export interface AdditionalData {
  supplierCode: string;
  subUnitCode: string;
  buyerEmail: string | undefined;
  brandCode: string | undefined;
  brandName: string | undefined;
  orderReferenceId: string | undefined;
  season: string | undefined;
  cgGroupingLevel: string | undefined;
}

interface BoxData {
  inputTotalQuantitesPerConfig: number | undefined;
  deviation: number | undefined;
  typeOfBoxRestriction: string | undefined;
  availabilityFromSupplier: Record<string, unknown> | undefined;
}

export async function sendEvent(
  group: SizingGroup | undefined,
  eventType: string,
  articles: Article[] | undefined,
  additionalData: AdditionalData | undefined,
  boxData?: BoxData | undefined
): Promise<void> {
  const processedData = processData(group, eventType, articles, additionalData, boxData);
  sendEventToProxyTracking(processedData);
}

function processData(
  group: SizingGroup | undefined,
  eventType: string,
  articles: Article[] | undefined,
  additionalData: AdditionalData | undefined,
  boxData: BoxData | undefined
): any {
  if (eventType === 'orderCreated') {
    return [
      {
        event_type: eventType,
        timestamp: new Date(),
        buyer_email: additionalData?.buyerEmail,
        order_reference_id: additionalData?.orderReferenceId,
        remarks: '',
      },
    ];
  }
  articles = articles || group?.articles;
  return articles?.map((item) => ({
    article_quantity: item.quantity,
    article_reference_id: item.referenceId,
    availability_from_supplier: boxData?.availabilityFromSupplier || null,
    brand_code: additionalData?.brandCode || null,
    brand_name: additionalData?.brandName || null,
    buyer_email: additionalData?.buyerEmail || null,
    buying_article_config_id: item.buyingArticleConfigId || null,
    cg_code: group?.commodityGroup?.code || null,
    cg_grouping_level: additionalData?.cgGroupingLevel || null,
    deviation: boxData?.deviation || null,
    event_type: eventType,
    group_key: group?.groupKey || null,
    input_total_quantity_per_config: boxData?.inputTotalQuantitesPerConfig || null,
    length_chart_code: group?.selectedSizeChart?.lengthChartCode || null,
    length_list: group?.selectedSizeChart?.lengthList || null,
    order_reference_id: additionalData?.orderReferenceId || null,
    order_status: '', // future use
    reco_level: group?.selectedSizeKey?.recommendationLevel || null,
    reco_strategy: group?.selectedSizeKey?.recommendationStrategy || null,
    reference_brand_code: group?.sizing?.referenceBrandCode || null,
    remarks: '',
    reviewed: group?.isReviewed || false,
    season_code: additionalData?.season || null,
    size_chart_code: group?.selectedSizeChart?.sizeChartCode || null,
    size_distribution: item.sizing || null,
    size_key_distribution_type: group?.selectedSizeKey?.distributionType || null,
    size_key_id: group?.selectedSizeKey?.sizeKeyId || null,
    size_list: group?.selectedSizeKey?.sizeList || null,
    size_structure: group?.sizeStructure || null,
    sizing_decision_auto_reused: !!(group?.reusedDecision && group?.reusedDecision.isAutoReused),
    sizing_decision_reused: !!(group?.reusedDecision && Object.keys(group?.reusedDecision).length),
    sizing_note: group?.sizingNote || null,
    subunit_code: additionalData?.subUnitCode || null,
    supplier_code: additionalData?.supplierCode || null,
    timestamp: new Date(),
    type_of_box_restriction: boxData?.typeOfBoxRestriction || null,
  }));
}
