import { runInAction } from 'mobx';
import { getSizingFromArticleQuantity } from '../../lib/article-utils';
import { boxingToStockRestrictions } from '../../lib/box-restrictions-utils';
import { sendSizingAppliedEvent } from '../../lib/fragment-helper';
import { BoxOptimisationPayloadPerArticle } from '../../models/box-restriction';
import { Article } from '../../models/sizing-data';
import boxRestrictionsService from '../../services/box-restrictions-service';
import { sendEvent } from '../../services/event-tracking';
import { SizingStore } from './sizing-store';

export class ResetBoxQuantitiesAction {
  async resetBoxQuantities(this: SizingStore, updatedArticle: Article): Promise<void> {
    const sizingGroup = this.sizingGroups.find((group) => group.articles.includes(updatedArticle));
    if (!sizingGroup) {
      throw new Error('Could not determine sizing group for article');
    }
    if (!sizingGroup.selectedSizeKey?.distribution) {
      throw new Error('The selected size key of the sizing group is missing a distribution');
    }

    await runInAction(async () => {
      for (const group of this.sizingGroups) {
        for (const article of group.articles) {
          if (article.referenceId === updatedArticle.referenceId) {
            // NOTE: This doesnt yet include blockOrderBoxing reset
            const idealQuantity = article.quantity - (article.boxing?.deviation || 0);
            const idealSizing = getSizingFromArticleQuantity(idealQuantity, group?.selectedSizeKey?.distribution);

            const boxOptimizationPayload: BoxOptimisationPayloadPerArticle[] = [
              {
                article_id: article.referenceId,
                desired_size_level_quantity: idealSizing || {},
                box_restriction_id: sizingGroup.boxRestriction?.boxRestrictionId as string,
                stock_restrictions: boxingToStockRestrictions(article.boxing),
              },
            ];
            const boxOptimizations = await boxRestrictionsService.optimizeBoxQuantities(boxOptimizationPayload);
            this.applyArticleOptimization(article, boxOptimizations[0], 'sizing', 'boxing', 'quantity');
            sendEvent(sizingGroup, 'applyBoxRestriction', undefined, {
              supplierCode: this.supplierCode,
              subUnitCode: this.subUnitCode,
              buyerEmail: this.buyerEmail,
              brandCode: this.brandCode,
              brandName: this.brandName,
              orderReferenceId: this.referenceId,
              season: this.seasonCode,
              cgGroupingLevel: this.groupingCgLevel,
            });
            sendSizingAppliedEvent(sizingGroup, [article]);
          }
        }
      }
    });
  }
}
