import isEqual from 'fast-deep-equal';
import { sendSizingAppliedEvent, sendSizingAppliedEventForGroups } from '../../lib/fragment-helper';
import { Distribution, DistributionType } from '../../models/sizing';
import { SheetArticleSet } from '../../models/quantities-template';
import { Article } from '../../models/sizing-data';
import { SizingStore } from './sizing-store';
import { SizingGroup } from '../../models/sizing-group';
import { findBRCandidateLotByLotName, getArticleSheetId } from '../../lib/xlsx-utils';
import { sendEvent } from '../../services/event-tracking';
import presizeBackend from '../../services/presize-backend';
import { runInAction } from 'mobx';

export class ChangeArticleSizingAction {
  changeArticleSizing(this: SizingStore, article: Article, newSizing: Distribution, newQuantity?: number): void {
    presizeBackend.setEditedQuantities(this.referenceId, article.referenceId, newSizing).then((_) => {
      runInAction(() => {
        article.sizing = newSizing;
        article.manuallyEdited = true;
        article.editedQuantities = newSizing;
        if (newQuantity) {
          article.quantity = newQuantity;
        }
      });
      const group = this.groupByArticleRefId[article.referenceId];
      const alteredGroup = JSON.parse(JSON.stringify(group)) as SizingGroup;
      if (alteredGroup.selectedSizeKey) {
        alteredGroup.selectedSizeKey.distributionType = DistributionType.MANUAL;
        alteredGroup.selectedSizeKey.recommendationStrategy = '';
        alteredGroup.selectedSizeKey.recommendationLevel = '';
      }
      sendEvent(alteredGroup, 'changeArticleSizing', [article], this.additionalData());
      sendSizingAppliedEvent(group, [article]);
    });
  }

  async changeArticleSizingBulk(this: SizingStore, articlesSet: SheetArticleSet): Promise<void> {
    const sizingGroupsToUpdate: SizingGroup[] = [];
    const articlesToUpdate: Article[] = [];

    await Promise.all(
      this.sizingGroups.map(async (sizingGroup) => {
        const updatedArticles = articlesToUpdate.length;
        const isBoxRestriction = sizingGroup.enableBox;

        if (isBoxRestriction) {
          const isSetGroupBoxRestriction = sizingGroup.articles.find(
            (article) => articlesSet[getArticleSheetId(article)]
          );
          if (isSetGroupBoxRestriction) {
            const updatedArticle = articlesSet[getArticleSheetId(isSetGroupBoxRestriction)];
            const lotNames = Object.keys(updatedArticle.availability);

            if (lotNames.length) {
              let boxRestriction = findBRCandidateLotByLotName(lotNames, this.boxRestrictions);

              if (isSetGroupBoxRestriction.boxing?.boxRestrictionId) {
                boxRestriction =
                  this.boxRestrictions.find((BR) => BR.id === isSetGroupBoxRestriction.boxing?.boxRestrictionId) ||
                  null;
              }

              await this.setGroupBoxRestriction(sizingGroup.groupKey, boxRestriction?.id);
            }
          }
        }

        const articlesForOptimization = sizingGroup.articles
          .filter((article) => articlesSet[getArticleSheetId(article)] && article.sizing)
          .filter((article) => {
            const updatedArticle = articlesSet[getArticleSheetId(article)];

            const isAvailabilityChanged = Object.keys(updatedArticle.availability).length !== 0;
            const isSizeChanged = Object.keys(updatedArticle.sizing).length !== 0;

            if (isAvailabilityChanged && isBoxRestriction) {
              if (article.boxing && 'boxDistributions' in article.boxing) {
                const updatedBox = article.boxing.boxDistributions.map((boxDistribution) => {
                  const availability = updatedArticle.availability[boxDistribution.box.name];

                  if (!availability) {
                    return boxDistribution;
                  }

                  return { ...boxDistribution, availability };
                });

                article.boxing.boxDistributions = updatedBox;

                articlesToUpdate.push(article);
                return true;
              }
            } else if (isSizeChanged) {
              const originalSizing = { ...article.sizing };
              let newQuantity = 0;

              Object.keys(article.sizing || {}).forEach((size) => {
                const newSizeQuantity = updatedArticle.sizing[size] || 0;
                article.sizing![size] = newSizeQuantity; //eslint-disable-line @typescript-eslint/no-non-null-assertion
                newQuantity += newSizeQuantity;
              });

              article.sizing = { ...article.sizing };
              article.quantity = newQuantity;
              if (!isEqual(article.sizing, originalSizing)) {
                presizeBackend.setEditedQuantities(this.referenceId, article.referenceId, article.sizing).then((_) => {
                  article.manuallyEdited = true;
                  article.editedQuantities = article.sizing;
                });
              }
            }
            articlesToUpdate.push(article);
            return false;
          });

        if (articlesToUpdate.length > updatedArticles) {
          sizingGroupsToUpdate.push(sizingGroup);
          sendEvent(sizingGroup, 'changeArticleSizing', undefined, this.additionalData());
        }
        if (articlesForOptimization.length) {
          return await this.optimizeBoxQuantities(sizingGroup);
        }
      })
    );

    sendSizingAppliedEventForGroups(sizingGroupsToUpdate, articlesToUpdate);
  }
}
